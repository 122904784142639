import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

export default function Timesheet() {

    useEffect(() => {
        document.title = "Portal – Timesheets";
    }, []);

    const Parse = require('parse');
    const currentUser = Parse.User.current();

    const [userIsAdmin, setUserIsAdmin] = useState(false);

    // const queryAdmin = () => {
    //     if (currentUser.id === 'WiGacaXnel' || currentUser.id === 'BKD0LlA2EC' || currentUser.id === 'NS7ecaLkAU') {
    //         setUserIsAdmin(true);
    //     }
    // }

    // useEffect(() => {
    //     queryAdmin(); 
    // },[])

    return <>
        {!userIsAdmin && (
            <Container>
                <div className="home-header">
                    <h2>Timesheets</h2>
                </div>
                <hr/>
                Timesheet edit coming soon.
            </Container>
        )}
        {userIsAdmin && (
            <Container>
                <div className="home-header">
                    <h2>Timesheets - Admin</h2>
                </div>
                <hr/>
                Admin timesheet view coming soon.
            </Container>
        )}
    </>
}
