import { useState, useEffect } from 'react';
import { Button, Modal, Spinner, Form} from 'react-bootstrap';
import Select from 'react-select';
import { customStyles } from '../../data/Data.js';
import axios from 'axios';

export default function FlagTask(props) {

    const [show, setShow] = useState(false);
    const [taskDisplayString, setTaskDisplayString] = useState('');
    const [staff, setStaff] = useState();
    const [localStaffOptions, setLocalStaffOptions] = useState();
    const [submitString, setSubmitString] = useState('Send');
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(()=> {
        var newArr = props.staffOptions;
        if (newArr[newArr.length - 1].label === 'zUnassigned Tasks') {
            newArr.splice(newArr.length - 1, 1);
        }
        if (newArr[0].value === props.currentUser.id) {
            newArr.splice(0, 1);        
        }
        setLocalStaffOptions(newArr);
    }, []);

    useEffect(()=> {
        (!staff) ? setIsDisabled(true) : setIsDisabled(false)
    }, [staff]);

    const handleClose = () =>  {
        setTaskDisplayString('');
        setSubmitString('Send');
        setStaff();
        setShow(false);
    }

    const handleShow = () => {
        if (props.row.values.flagDisplay === 'true') {
            setSubmitString('Remove');
        } else {
            setSubmitString('Send');
        };
        var taskString = '';
        taskString += props.row.values.actionTextDisplay;
        if (props.row.values.projStringDisplay.length > 0) {
            taskString += ' ';
            taskString += props.row.values.projStringDisplay;
        }
        setTaskDisplayString(taskString);
        setShow(true);
    }

    const flagThisTask = () => {
        setSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('Task'));
        query.get(props.row.values.idDisplay)
        .then((task) => {
            var staffObject = staff.object;
            task.set('flag', true);
            task.save()
            .then((res)=>{
                if (staffObject !== undefined) {
                    if ((staffObject.id !== props.currentUser.id) && (staffObject.attributes.slackNotifHook !== undefined)) {
                        const url = staffObject.attributes.slackNotifHook;
                        var userString = '';
                        if (staffObject.attributes.slackUserId.length > 0) {
                            userString = '<@' + staffObject.attributes.slackUserId + '>';
                        } else {
                            userString = staffObject.attributes.firstName + ' ' + staffObject.attributes.lastName;
                        }
                        const data = { text: 'Hi '+ userString + ' ' + 
                            props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + 
                            ' has requested your help with a task:\n' + taskDisplayString};
    
                        axios.post(url, JSON.stringify(data), {
                            withCredentials: false,
                            transformRequest: [
                                (data, headers) => {
                                    return data;
                                },
                            ],
                        });
                    }
                }
                const dataCopy = props.taskData;
                var thisTask = dataCopy[props.row.index];
                thisTask.flagDisplay = 'true';
                dataCopy[props.row.index] = thisTask;
                props.setTaskData(dataCopy);
                handleClose();
            }).catch((error)=>{
                alert('Please send screenshot of this error message: ' + error.message);
                setSubmitString('Send');
            })
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitString('Send');
        })
    }

    const unflagThisTask = () => {
        setSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('Task'));
        query.get(props.row.values.idDisplay)
        .then((task) => {
            task.set('flag', false);
            task.save()
            .then((res)=>{
                const dataCopy = props.taskData;
                var thisTask = dataCopy[props.row.index];
                thisTask.flagDisplay = 'false';
                dataCopy[props.row.index] = thisTask;
                props.setTaskData(dataCopy);
                handleClose();
            }).catch((error)=>{
                alert('Please send screenshot of this error message: ' + error.message);
                setSubmitString('Remove');
            })
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitString('Remove');
        })
    }

    if (props.row.values.isComplete === 'true') {
        return null;
    } else if (props.row.values.flagDisplay === 'true') {
        return <>
            <Button variant='link' size="sm" className="help" onClick={handleShow}>
                <i className="fas fa-hand-paper"></i>
            </Button>
            <Modal show={show} onHide={handleClose} onExited={props.handleExit} centered size='md' backdrop="static">
                <Modal.Header className='modal-header' closeButton>
                    <Modal.Title>Help Request</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body'>
                    {taskDisplayString}
                </Modal.Body>
                <Modal.Footer className='modal-header'>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={unflagThisTask}
                    >
                        {submitString}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    } else {
        return <>
            <Button variant='link' className="help" size="sm" onClick={handleShow}>
                <i className="far fa-hand-paper"></i>
            </Button>
            <Modal show={show} onHide={handleClose} onExited={props.handleExit} centered size='md' backdrop="static">
                <Modal.Header className='modal-header' closeButton>
                    <Modal.Title>Help Request</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body'>
                    {taskDisplayString}
                    <Form.Group className="mt-2">
                        <Form.Label><em>Request help from</em></Form.Label>
                        <Select 
                            styles={customStyles}
                            options={localStaffOptions}
                            onChange={(e)=>setStaff(e)}
                            value={staff}
                            defaultValue={staff}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className='modal-header'>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={flagThisTask}
                        disabled={isDisabled}
                    >
                        {submitString}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    }
}
