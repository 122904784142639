import { useState, useEffect } from 'react';
import { Button, Modal, Form, Row, Col, Spinner} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/en-au';

export default function EditProjectDeadline(props) {

    const [show, setShow] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [projectDisplayString, setProjectDisplayString] = useState('');
    const [deadline, setDeadline] = useState();
    const [submitString, setSubmitString] = useState('Save Project');

    useEffect(()=> {
        (!deadline) ? setIsDisabled(true) : setIsDisabled(false)
    }, [deadline]);

    const handleClose = () =>  {
        setProjectDisplayString('');
        setDeadline('');
        setSubmitString('Save Project');
        setShow(false);
    }

    const handleShow = () => {
        setProjectDisplayString(props.row.values.projectNumberDisplay + ' — ' + props.row.values.projectNameDisplay);
        setDeadline(props.row.values.deadlineValueDisplay);
        setShow(true);
    }

    const handleSubmit = () => {
        setSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('Project'));
        query.get(props.row.values.idDisplay)
        .then((project) =>{
            project.set('deadline', deadline);
            project.save()
            .then((res)=>{
                if (!props.fromDashboard) {
                    const dataCopy = props.projectData;
                    var thisProject = dataCopy[props.row.index];
                    if (deadline) {
                        thisProject.deadlineValueDisplay = deadline;
                        thisProject.deadlineDisplay = moment(deadline).locale('en-au').calendar({
                            sameDay: '[Today]',
                            nextDay: '[Tomorrow]',
                            nextWeek: 'DD MMM',
                            lastDay: '[Yesterday]',
                            lastWeek: 'DD MMM',
                            sameElse: 'DD MMM' 
                        });
                    }
                    dataCopy[props.row.index] = thisProject;
                    props.setProjectData(dataCopy);
                }
                handleClose();
            }).catch((error)=>{
                alert('Please send screenshot of this error message: ' + error.message);
                setSubmitString('Save Project');
            })
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitString('Save Project');
        })
    }

    const DeadlineDisplay = () => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        if (props.row.values.deadlineValueDisplay < today) {
            return <>
                {!props.fromDashboard && (
                    <div onDoubleClick={handleShow} >
                        <span style={{color:'var(--danger)', fontSize:'1em'}}>{props.cell}</span> <i style={{color:'var(--danger)'}} className="fas fa-exclamation"></i>
                    </div>
                )}
                {props.fromDashboard && (
                    <div onDoubleClick={handleShow} >
                        <span style={{color:'var(--danger)', fontSize:'0.8em'}}>{props.cell}</span> <i style={{color:'var(--danger)'}} className="fas fa-exclamation"></i>
                    </div>
                )}
            </>
        } else if (props.row.values.deadlineValueDisplay < tomorrow) {
            return <>
                {!props.fromDashboard && (
                    <div onDoubleClick={handleShow} >
                        <span style={{color:'var(--dark-orange)', fontSize:'1em'}}>{props.cell}</span>
                    </div>
                )}
                {props.fromDashboard && (
                    <div onDoubleClick={handleShow} >
                        <span style={{color:'var(--dark-orange)', fontSize:'0.8em'}}>{props.cell}</span>
                    </div>
                )}
            </>
        } else {
            return <>
                {!props.fromDashboard && (
                    <div onDoubleClick={handleShow} >
                        <span style={{color:'var(--text)', fontSize:'1em'}}>{props.cell}</span>
                    </div>
                )}
                {props.fromDashboard && (
                    <div onDoubleClick={handleShow} >
                        <span style={{color:'var(--text)', fontSize:'0.8em'}}>{props.cell}</span>
                    </div>
                )}
            </>
        }
    }

    return <>
        <DeadlineDisplay />
        <Modal show={show} onHide={handleClose} onExited={props.handleExit} centered size='md' backdrop="static">
            <Modal.Header className='modal-header' closeButton>
                <Modal.Title>{projectDisplayString}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Deadline</Form.Label>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                className='form-control'
                                selected={deadline}
                                minDate={new Date()}
                                todayButton="Today"
                                onChange={(date)=> setDeadline(date)}
                            />
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                >
                    {submitString}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
