import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Form, Row, Col, Spinner, Alert} from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/en-au';
import { customStyles } from '../../data/Data.js'
import axios from 'axios';


export default function EditTask(props) {

    const [show, setShow] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlert2, setShowAlert2] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [initiallyComplete, setInitiallyComplete] = useState(false);
    const [staff, setStaff] = useState();
    const [action, setAction] = useState();
    const [recurringType, setRecurringType] = useState();
    const [deadline, setDeadline] = useState();
    const [project, setProject] = useState();
    const [checklist, setChecklist] = useState();
    const [complete, setComplete] = useState();
    const [recurring, setRecurring] = useState();
    const [link, setLink] = useState();
    const [url, setUrl] = useState();
    const [submitString, setSubmitString] = useState('Save Task');

    const defaultSingleValue = (options, propsValue) => {
        return options.filter(option => option.value === propsValue);
    }

    useEffect(()=>{
        setStaff(defaultSingleValue(props.staffOptions, props.row.values.personDisplay));
    }, [props.staffOptions])

    useEffect(()=>{
        setProject(defaultSingleValue(props.projectOptions, props.row.values.projObjIdDisplay));
    }, [props.projectOptions])

    useEffect(()=>{
        setChecklist(defaultSingleValue(props.checklistOptions, props.row.values.checklistDisplay));
    }, [props.checklistOptions])

    useEffect(()=> {
        if (link) {
            var validUrl = require('valid-url');
            if (validUrl.isWebUri(url)) {
                (!staff || !action || !deadline) ? setIsDisabled(true) : setIsDisabled(false)
            } else {
                setIsDisabled(true);
            }
        } else {
            (!staff || !action || !deadline) ? setIsDisabled(true) : setIsDisabled(false)
        }
    }, [staff, action, deadline, link, url]);

    const handleClose = () =>  {
        setStaff(defaultSingleValue(props.staffOptions, props.row.values.personDisplay));
        setAction('');
        setDeadline('');
        setProject('');
        setChecklist('');
        setComplete('');
        setRecurring('');
        setRecurringType('');
        setLink('');
        setUrl('');
        setShow(false);
        setShowAlert(false);
        setShowAlert2(false);
        setSubmitString('Save Task');
    }

    const handleShow = () => {
        if (props.fromDashboard) {
            props.hideLastPopover();
        }
        if (props.row.values.linkDisplay === 'true') {
            setLink(true);
        } else {
            setLink(false);
        }
        setUrl(props.row.values.urlDisplay)
        setAction(props.row.values.actionTextDisplay);
        setRecurringType(defaultSingleValue(frequencyOptions, props.row.values.recurringDisplay));
        setDeadline(props.row.values.deadlineValueDisplay);
        if (props.row.values.isComplete === 'true') {
            setComplete(true);
            setInitiallyComplete(true);
        } else {
            setComplete(false);
        }
        setRecurring(props.row.values.isRecurring);
        setStaff(defaultSingleValue(props.staffOptions, props.row.values.personDisplay));
        setProject(defaultSingleValue(props.projectOptions, props.row.values.projObjIdDisplay));
        setChecklist(defaultSingleValue(props.checklistOptions, props.row.values.checklistDisplay));
        setShow(true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const taskId = props.row.values.idDisplay;

        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('Task'));
        query.get(taskId)
        .then((task) => {

            var isProjectNull = false;
            var isNewProject = false;
            if (project.value === 'null') {
                isProjectNull = true;
            }
            if (project.value !== props.row.values.projObjIdDisplay) {
                isNewProject = true;
            }

            var isChecklistNull = false;
            var isNewChecklist = false;
            if ((checklist.length = 0) || (checklist.value === 'null')) {
                isChecklistNull = true;
            } else if (checklist.value !== undefined) {
                isNewChecklist = true;
            }

            task.set('assignedTo', staff.object);
            task.set('action', action);
            task.set('deadline', deadline);
            if (recurring) {
                task.set('recurring', true);
                task.set('recurringType', recurringType.value);
            } else {
                task.set('recurring', false);
                task.set('recurringType', undefined);
            }
            if (link) {
                task.set('link', true);
                task.set('url', url);
            } else {
                task.set('link', false);
                task.set('url', '');
            }
            if (complete) {
                task.set('flag', false);
                task.set('star', false);
                task.set('complete', true);
                var today = new Date();
                task.set('completedOn', today);
            } else {
                task.set('complete', false);
            }
            if (isProjectNull) {
                task.set('project', null);
            } else if (isNewProject) {
                task.set('project', project.object);
            }
            if (isChecklistNull) {
                task.set('hasChecklist', false);
                task.set('list', null);
                task.set('check', null);
            } else if (isNewChecklist) {
                var checkArray = [];
                for (let i = 0; i < checklist.object.attributes.list.length; i++) {
                    checkArray[i] = false;
                }
                task.set('hasChecklist', true);
                task.set('list', checklist.object);
                task.set('check', checkArray);
            }
            task.save()
            .then((res)=>{
                if (staff.object !== undefined) {
                    if ((staff.object.id !== props.currentUser.id) && (staff.object.attributes.slackNotifHook !== undefined)) {
                        const url = staff.object.attributes.slackNotifHook;
                        var userString = '';
                        if (staff.object.attributes.slackUserId.length > 0) {
                            userString = '<@' + staff.object.attributes.slackUserId + '>';
                        } else {
                            userString = staff.object.attributes.firstName + ' ' + staff.object.attributes.lastName;
                        }
                        var taskString = '';
                        if (isProjectNull) {
                            taskString = action;
                        } else {
                            taskString = action + '\n' + project.label;
                        }
                        var deadlineDisp = moment(deadline).locale('en-au').calendar({
                            // sameDay: '[Today]',
                            // nextDay: '[Tomorrow]',
                            nextWeek: 'ddd DD MMM h:mm A',
                            // lastDay: '[Yesterday]',
                            lastWeek: 'ddd DD MMM h:mm A',
                            sameElse: 'ddd DD MMM h:mm A' 
                        });
                        const data = { text: 'Hi '+ userString + ' ' + 
                            props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + 
                            ' has assigned you a task:\n' + taskString + '\nDue: ' + deadlineDisp};

                        axios.post(url, JSON.stringify(data), {
                            withCredentials: false,
                            transformRequest: [
                                (data, headers) => {
                                    return data;
                                },
                            ],
                        });
                    }
                }
                if (!props.fromDashboard) {
                    const dataCopy = props.taskData;
                    var thisTask = dataCopy[props.row.index];
                    if (complete) {
                        thisTask.isComplete = 'true';
                        thisTask.completeValueDisplay = today;
                        thisTask.completeDisplay = moment(today).locale('en-au').calendar({
                            // sameDay: '[Today]',
                            // nextDay: '[Tomorrow]',
                            nextWeek: 'ddd DD MMM h:mm A',
                            // lastDay: '[Yesterday]',
                            lastWeek: 'ddd DD MMM h:mm A',
                            sameElse: 'ddd DD MMM h:mm A' 
                        });
                    } else {
                        if (recurring) {
                            thisTask.isRecurring = true;
                            if (res.get('recurringCompleteOnce') === true) {
                                thisTask.isComplete = 'both';
                            } else {
                                thisTask.isComplete = 'false';
                            }
                            thisTask.recurringDisplay = recurringType.value;
                        } else {
                            thisTask.isRecurring = false;
                            thisTask.recurringDisplay = '';
                            thisTask.completeDisplay = '';
                            thisTask.completeValueDisplay = 0;
                            thisTask.isComplete = 'false';
                        } 
                    }
                    if (staff.value && staff.label) {
                        thisTask.personDisplay = staff.value;
                        thisTask.personNameDisplay = staff.label;
                    }
                    if (action) {
                        thisTask.actionTextDisplay = action;
                    }
                    if (link && url) {
                        thisTask.linkDisplay = 'true';
                        thisTask.urlDisplay = url;
                        thisTask.actionDisplay = <a href={url} target='_blank'>{action}</a>;
                    } else {
                        thisTask.linkDisplay = 'false';
                        thisTask.urlDisplay = '';
                        thisTask.actionDisplay = action;
                    }
                    if (deadline) {
                        thisTask.deadlineValueDisplay = deadline;
                        thisTask.deadlineDisplay = moment(deadline).locale('en-au').calendar({
                            // sameDay: '[Today]',
                            // nextDay: '[Tomorrow]',
                            nextWeek: 'ddd DD MMM h:mm A',
                            // lastDay: '[Yesterday]',
                            lastWeek: 'ddd DD MMM h:mm A',
                            sameElse: 'ddd DD MMM h:mm A' 
                        });
                    }
                    if (isProjectNull) {
                        thisTask.projObjIdDisplay = 'null';
                        thisTask.projectDisplay = '';
                        thisTask.projStringDisplay = '';
                    } else if (isNewProject && project.value && project.label && project.number) {
                        thisTask.projObjIdDisplay = project.value;
                        thisTask.projectDisplay = <Link target='_blank' to={'/staff/' + project.number}>
                                {project.label}
                            </Link>;
                        thisTask.projStringDisplay = project.label;
                    }
                    if (isChecklistNull) {
                        thisTask.hasChecklistDisplay = 'false';
                        thisTask.checklistDisplay = '';
                        thisTask.checkArrayDisplay = '';
                    } else if (isNewChecklist) {
                        var checkArray = [];
                        for (let i = 0; i < checklist.object.attributes.list.length; i++) {
                            checkArray[i] = false;
                        }
                        thisTask.hasChecklistDisplay = 'true';
                        thisTask.checklistDisplay = checklist.value;
                        thisTask.checkArrayDisplay = checkArray.toString();
                    }
                    dataCopy[props.row.index] = thisTask;
                    props.setTaskData(dataCopy);
                }
                handleClose();
            }).catch((error)=>{
                alert('Please send screenshot of this error message: ' + error.message);
                setSubmitString('Save Task');
            })
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitString('Save Task');
        })
    }

    const fadeAlert = () => {
        setTimeout(()=>{
            setShowAlert(false);
            setShowAlert2(false);
        }, 3000);
    }

    const frequencyOptions = [
        { value: 'day', label: 'Daily'},
        { value: 'week', label: 'Weekly'},
        { value: 'fortnight', label: 'Fortnightly'},
        { value: 'month', label: 'Monthly'},
        { value: 'quarter', label: 'Quarterly'},
        { value: 'year', label: 'Annually'},
    ];

    function handleCompleteSwitch(e) {
        if (e && recurring) {
            setShowAlert(true);
            fadeAlert();
        } else {
            setComplete(e);
        }
    }

    function handleRecurringSwitch(e) {
        setRecurring(e);
        if (e) {
            setRecurringType ({ value: 'day', label: 'Daily'});
        }
        if (!e) {
            setRecurringType('');
        }
        if (e && complete) {
            setComplete(false);
        }
    }

    function handleRecurringDropdown(e) {
        handleRecurringSwitch(true);
        setRecurringType(e);
    }

    function handleLinkChange(e) {
        setLink(e);
        // if (!e) {
        //     setUrl('');
        // }
    }

    function handleUrlChange(e) {
        if (e.length > 0) {
            setLink(true);
        } else {
            setLink(false);
        }
        var validUrl = require('valid-url');
        if (validUrl.isWebUri(e) || e.length === 0) {
            setUrl(e);
        } else {
            setUrl(e);
            setShowAlert2(true);
            fadeAlert();
        }
    }
    
    return <>
        <Button variant='outline-secondary' className='square-button' onClick={handleShow}>
            <i className="fas fa-pen"></i>
        </Button>
        <Modal show={show} onHide={handleClose} onExited={props.handleExit} centered size='lg' backdrop="static">
            <Modal.Header className='modal-header' closeButton>
                <Modal.Title>Edit Task</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Form.Group as={Col} md="4" className="mb-3">
                            <Form.Label>Assigned To</Form.Label>
                            <Select 
                                styles={customStyles}
                                options={props.staffOptions}
                                value={staff}
                                defaultValue={staff}
                                onChange={e=>setStaff(e)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" className="mb-3">
                            <Form.Label>Project</Form.Label>
                            <Select 
                                styles={customStyles}
                                options={props.projectOptions}
                                value={project}
                                defaultValue={project}
                                onChange={e=>setProject(e)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" className="mb-3">
                            <Form.Label>Deadline</Form.Label>
                            <DatePicker
                                dateFormat="dd/MM/yyyy h:mm aa"
                                className='form-control'
                                selected={deadline}
                                showTimeSelect
                                minDate={new Date()}
                                todayButton="Today"
                                onChange={(date)=> setDeadline(date)}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Action</Form.Label>
                            <Form.Control
                                id='action-text'
                                type='text'
                                value={action}
                                onChange={(e) => {setAction(e.target.value)}}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="4" className="mb-3">
                            <Form.Label>Checklist</Form.Label>
                            <Select 
                                styles={customStyles}
                                options={props.checklistOptions}
                                value={checklist}
                                defaultValue={checklist}
                                onChange={e=>setChecklist(e)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="2" className="mb-3">
                            <Form.Label>Recurring</Form.Label>
                            <Form.Check
                                type="switch"
                                label=""
                                checked={recurring}
                                onChange={(e) => handleRecurringSwitch(e.target.checked)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="2" className="mb-3">
                            <Form.Label>Enable link</Form.Label>
                            <Form.Check
                                type="switch"
                                label=""
                                checked={link}
                                onChange={(e) => handleLinkChange(e.target.checked)}
                            />
                        </Form.Group>
                        {initiallyComplete && (
                            <Form.Group as={Col} md="2" className="mb-3">
                                <Form.Label>Complete</Form.Label>
                                <Form.Check
                                    type="switch"
                                    label=""
                                    checked={complete}
                                    onChange={(e) => handleCompleteSwitch(e.target.checked)}
                                />
                            </Form.Group>
                        )}
                    </Row>
                    <Row>
                        {recurring && (
                            <Form.Group as={Col} md="3" className="mb-3">
                                <Form.Label>Recurring Type</Form.Label>
                                <Select 
                                    styles={customStyles}
                                    options={frequencyOptions}
                                    value={recurringType}
                                    defaultValue={recurringType}
                                    onChange={e=>handleRecurringDropdown(e)}
                                />
                            </Form.Group>
                        )}
                        {link && (
                            <Form.Group as={Col} className="mb-3">
                                <Form.Label>Link Url</Form.Label>
                                <Form.Control
                                    id='action-text'
                                    type='text'
                                    value={url}
                                    onChange={(e) => {handleUrlChange(e.target.value)}}
                                />
                            </Form.Group>
                        )}
                    </Row>
                    <Alert show={showAlert} variant="danger">
                        To complete a recurring task please use the complete button workflow.
                    </Alert>
                    <Alert show={showAlert2} variant="danger">
                        Invalid URL – Please format correctly with https:// etc
                    </Alert>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                >
                    {submitString}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
