import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Form, Row, Col, Spinner } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/en-au';
import { customStyles } from '../../data/Data.js'
import axios from 'axios';

export default function EditProject(props) {

    const [show, setShow] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [projectDisplayString, setProjectDisplayString] = useState('');
    const [errorText, setErrorText] = useState('');
    const [manager, setManager] = useState();
    const [second, setSecond] = useState();
    const [status, setStatus] = useState();
    const [deadline, setDeadline] = useState();
    const [comment, setComment] = useState();
    const [editStatusOptions, setEditStatusOptions] = useState([
        {"value" : "Quoting", "label" : "Quoting"}, 
        {"value" : "Undecided", "label" : "Undecided"}, 
        {"value" : "Active", "label" : "Win"},
        {"value" : "Site Inspection", "label" : "Site Inspection"},
        {"value" : "Internal Review", "label" : "Internal Review"},
        {"value" : "C10 Review", "label" : "C10 Review"},
        {"value" : "SD Review", "label" : "SD Review"},
        {"value" : "Loss", "label" : "Loss"},
        {"value" : "Lead", "label" : "Lead"},
        {"value" : "FP on hold", "label" : "FP on hold"},
    ]);
    const [submitString, setSubmitString] = useState('Save Project');

    const statusOptions = [
        {"value" : "Quoting", "label" : "Quoting"}, 
        {"value" : "Undecided", "label" : "Undecided"}, 
        {"value" : "Lead", "label" : "Lead"},
        {"value" : "FP on hold", "label" : "FP on hold"}, 
        {"value" : "Active", "label" : "Active"},
        {"value" : "Site Inspection", "label" : "Site Inspection"},
        {"value" : "Internal Review", "label" : "Internal Review"},
        {"value" : "C10 Review", "label" : "C10 Review"},
        {"value" : "SD Review", "label" : "SD Review"},
        {"value" : "On hold", "label" : "On hold"}, 
        {"value" : "Complete", "label" : "Complete"}, 
        {"value" : "Loss", "label" : "Loss"},
    ];

    const defaultSingleValue = (options, propsValue) => {
        return options.filter(option => option.value === propsValue);
    }

    const defaultSingleValue2 = (options, propsValue) => {
        return options.filter(option => option.value === propsValue)[0];
    }


    useEffect(()=>{
        setManager(defaultSingleValue2(props.projectManagerOptions, props.row.values.personDisplay));
        setSecond(defaultSingleValue2(props.projectManagerOptions, props.row.values.secondDisplay));
    }, [props.projectManagerOptions])

    useEffect(()=> {
        if (manager === second) {
            setErrorText('Pilot and copilot cannot be same');
            setIsDisabled(true);
        } else {
            setErrorText('');
            (!manager || !second || !status || !deadline) ? setIsDisabled(true) : setIsDisabled(false)
        }
    }, [manager, second, status, deadline]);

    const handleClose = () =>  {
        setManager(defaultSingleValue2(props.projectManagerOptions, props.row.values.personDisplay));
        setSecond(defaultSingleValue2(props.projectManagerOptions, props.row.values.secondDisplay));
        setStatus(defaultSingleValue(statusOptions, props.row.values.statusDisplay));
        setComment('');        
        setProjectDisplayString('');
        setDeadline('');
        setShow(false);
        setSubmitString('Save Project');
    }

    const handleShow = () => {
        const pastStatus = props.row.values.statusDisplay;
        if (pastStatus === 'Quoting' || pastStatus === 'Undecided' || pastStatus === 'Lead' || pastStatus === 'FP on hold') {
            setEditStatusOptions([
                {"value" : "Quoting", "label" : "Quoting"}, 
                {"value" : "Undecided", "label" : "Undecided"}, 
                {"value" : "Lead", "label" : "Lead"},
                {"value" : "FP on hold", "label" : "On hold"},
            ]);
        } else if (pastStatus === 'Active' || pastStatus === 'Site Inspection' || pastStatus === 'Internal Review' || pastStatus === 'C10 Review' || pastStatus === 'SD Review' || pastStatus === 'On hold') {
            setEditStatusOptions([
                {"value" : "Active", "label" : "Active"},
                {"value" : "Site Inspection", "label" : "Site Inspection"},
                {"value" : "Internal Review", "label" : "Internal Review"},
                {"value" : "C10 Review", "label" : "C10 Review"},
                {"value" : "SD Review", "label" : "SD Review"},
                {"value" : "On hold", "label" : "On hold"},
                {"value" : "Quoting", "label" : "Quoting"}, 
                {"value" : "Loss", "label" : "Loss"}, 
            ]);
        } else if (pastStatus === 'Complete') {
            setEditStatusOptions([
                {"value" : "Complete", "label" : "Complete"},
                {"value" : "Quoting", "label" : "Quoting"}, 
                {"value" : "Active", "label" : "Active"},
            ]);
        } else if (pastStatus === 'Loss') {
            setEditStatusOptions([
                {"value" : "Loss", "label" : "Loss"}, 
                {"value" : "Quoting", "label" : "Quoting"}, 
            ]);
        } else {
            setEditStatusOptions([
                {"value" : "Quoting", "label" : "Quoting"}, 
                {"value" : "Undecided", "label" : "Undecided"}, 
                {"value" : "Lead", "label" : "Lead"},
                {"value" : "FP on hold", "label" : "FP on hold"}, 
                {"value" : "Active", "label" : "Active"},
                {"value" : "Site Inspection", "label" : "Site Inspection"},
                {"value" : "Internal Review", "label" : "Internal Review"},
                {"value" : "C10 Review", "label" : "C10 Review"},
                {"value" : "SD Review", "label" : "SD Review"},
                {"value" : "On hold", "label" : "On hold"}, 
                {"value" : "Complete", "label" : "Complete"}, 
                {"value" : "Loss", "label" : "Loss"},
            ]);
        }
        setProjectDisplayString(props.row.values.projectNumberDisplay + ' — ' + props.row.values.projectNameDisplay);
        setManager(defaultSingleValue2(props.projectManagerOptions, props.row.values.personDisplay));
        setSecond(defaultSingleValue2(props.projectManagerOptions, props.row.values.secondDisplay));
        setStatus(defaultSingleValue(statusOptions, props.row.values.statusDisplay));
        setComment(props.row.values.commentDisplay);
        setDeadline(props.row.values.deadlineValueDisplay);
        setShow(true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('Project'));
        query.equalTo('projectId', parseInt(props.row.values.projectNumberDisplay));
        query.find()
        .then(function(res) {
            if ((res.length < 1 && props.row.values.projectNumberDisplay.length === 6) || props.row.values.projectNumberDisplay === props.row.values.projectNumberDisplay) {
                
                const query2 = new Parse.Query(Parse.Object.extend('Project'));
                query2.get(props.row.values.idDisplay)
                .then((project) =>{
                    if (manager.object) {
                        project.set('managerId', manager.object);
                    }
                    if (second.object) {
                        project.set('secondId', second.object);
                    }
                    if (status.value) {
                        project.set('status', status.value);
                    }
                    project.set('comment', comment);
                    project.set('deadline', deadline);

                    project.save()
                    .then((res2)=>{
                        if (manager.object !== undefined) {
                            if ((manager.object.id !== props.currentUser.id) && 
                                (manager.object.id !== props.row.values.personDisplay) &&
                                (manager.object.attributes.slackNotifHook !== undefined)) {
                                const url = manager.object.attributes.slackNotifHook;
                                var userString = '';
                                if (manager.object.attributes.slackUserId.length > 0) {
                                    userString = '<@' + manager.object.attributes.slackUserId + '>';
                                } else {
                                    userString = manager.object.attributes.firstName + ' ' + manager.object.attributes.lastName;
                                }
                                const data = { text: 'Hi '+ userString + ' ' + 
                                    props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + 
                                    ' has assigned you a project:\n' + '<https://portal.loteconsulting.com/staff/' + 
                                    props.row.values.projectNumberDisplay + '|' + props.row.values.projectNumberDisplay + 
                                    ' — ' + props.row.values.projectNameDisplay + '>'};
            
                                axios.post(url, JSON.stringify(data), {
                                    withCredentials: false,
                                    transformRequest: [
                                        (data, headers) => {
                                            return data;
                                        },
                                    ],
                                });
                            }
                        }
                        if (second.object !== undefined) {
                            if ((second.object.id !== props.currentUser.id) && 
                                (second.object.id !== props.row.values.secondDisplay) && 
                                (second.object.attributes.slackNotifHook !== undefined)) {
                                const url = second.object.attributes.slackNotifHook;
                                var userString = '';
                                if (second.object.attributes.slackUserId.length > 0) {
                                    userString = '<@' + second.object.attributes.slackUserId + '>';
                                } else {
                                    userString = second.object.attributes.firstName + ' ' + second.object.attributes.lastName;
                                }
                                const data = { text: 'Hi '+ userString + ' ' + 
                                    props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + 
                                    ' has set you as copilot for a project:\n' + '<https://portal.loteconsulting.com/staff/' + 
                                    props.row.values.projectNumberDisplay + '|' + props.row.values.projectNumberDisplay + 
                                    ' — ' + props.row.values.projectNameDisplay + '>'};
            
                                axios.post(url, JSON.stringify(data), {
                                    withCredentials: false,
                                    transformRequest: [
                                        (data, headers) => {
                                            return data;
                                        },
                                    ],
                                });
                            }
                        }
                        handleClose();
                    }).catch((error)=>{
                        alert('Please send screenshot of this error message: ' + error.message);
                        setSubmitString('Save Project');
                    })
                }).catch((error)=>{
                    alert('Please send screenshot of this error message: ' + error.message);
                    setSubmitString('Save Project');
                })
            } else if (res.length >= 1) {
                alert('Error: This project number already exists. Look for the project in the database!');
                setSubmitString('Save Project');
            } else {
                alert('Error: Project number incorrect format');
                setSubmitString('Save Project');
            }
        }, (error) => {
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitString('Save Project');
        });
    }
    
    return <>
        <Button variant='outline-secondary' className='square-button mt-1' onClick={handleShow}>
            <i className="fas fa-pen"></i>
        </Button>
        <Modal show={show} onHide={handleClose} onExited={props.handleExit} centered size='md' backdrop="static">
            <Modal.Header className='modal-header' closeButton>
                <Modal.Title>{projectDisplayString}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-2">
                        <Row>
                            <Form.Group as={Col} md="6" className="mb-3">
                                <Form.Group controlId="form-project-manager">
                                    <Form.Label>Pilot</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        id='form-project-manager'
                                        options={props.projectManagerOptions}
                                        value={manager}
                                        defaultValue={manager}
                                        onChange={e=>setManager(e)}
                                    />
                                </Form.Group>
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-3">
                                <Form.Group controlId="form-project-manager">
                                    <Form.Label>Copilot</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        id='form-project-manager'
                                        options={props.projectManagerOptions}
                                        value={second}
                                        defaultValue={second}
                                        onChange={e=>setSecond(e)}
                                    />
                                </Form.Group>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md="6" className="mb-3">
                                <Form.Group>
                                    <Form.Label>Status</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        options={editStatusOptions}
                                        value={status}
                                        defaultValue={status}
                                        onChange={e=>setStatus(e)}
                                    />
                                </Form.Group>
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-3">
                                <Form.Label>Deadline</Form.Label>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className='form-control'
                                    selected={deadline}
                                    minDate={new Date()}
                                    todayButton="Today"
                                    onChange={(date)=> setDeadline(date)}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label>Comment</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <div className='form-fail'>
                                {errorText}
                            </div>
                        </Row>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                >
                    {submitString}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
