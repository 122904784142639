import { useState, useEffect } from 'react';
import { Button, Form, Modal, Spinner, Row, Stack, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import RangeSlider from 'react-bootstrap-range-slider';
import moment from 'moment';
import 'moment/locale/en-au';
import { customStyles, officeOptions, leaveOptions } from '../../data/Data.js'
import axios from 'axios';

export default function AddTimesheet(props) {

    const Parse = require('parse');

    const [active, setActive] = useState(false);
    const [checkedActive, setCheckedActive] = useState(false);
    const [activeId, setActiveId] = useState('');

    const [localProjectOptions, setLocalProjectOptions] = useState([]);
    const [allProjects, setAllProjects] = useState([]);
    const [isAllProjectsLoaded, setIsAllProjectsLoaded] = useState(false);
    
    const [start, setStart] = useState(new Date());
    const [location, setLocation] = useState('');
    const [breakTime, setBreakTime] = useState(0.5);
    const [showHoursLeave, setShowHoursLeave] = useState(true);
    const [hours, setHours] = useState(0);
    const [leaveHours, setLeaveHours] = useState(7.5);
    const [leaveType, setLeaveType] = useState('');
    const [end, setEnd] = useState(new Date());

    const [showStart, setShowStart] = useState(false);
    const [submitStringStart, setSubmitStringStart] = useState('Create Timesheet');
    const [isDisabledStart, setIsDisabledStart] = useState(true);

    const [showLeave, setShowLeave] = useState(false);
    const [submitStringLeave, setSubmitStringLeave] = useState('Lodge Leave');
    const [isDisabledLeave, setIsDisabledLeave] = useState(true);

    const [zeroTimesheet, setZeroTimesheet] = useState(false);
    const [showEnd, setShowEnd] = useState(false);
    const [submitStringEnd, setSubmitStringEnd] = useState('Submit Timesheet');
    const [submitStringDelete, setSubmitStringDelete] = useState('Delete Timesheet');
    const [isDisabledEnd, setIsDisabledEnd] = useState(true);

    const [rangeTotal, setRangeTotal] = useState(0);
    const [rangeError, setRangeError] = useState(false);
    const [projCount, setProjCount] = useState(1);
    const [projMin, setProjMin] = useState(true);
    const [projMax, setProjMax] = useState(false);

    const [show1, setShow1] = useState(true);
    const [manual1, setManual1] = useState(false);
    const [project1, setProject1] = useState();
    const [range1, setRange1] = useState(0);
    const [projectNumber1, setProjectNumber1] = useState('');

    const [show2, setShow2] = useState(false);
    const [manual2, setManual2] = useState(false);
    const [project2, setProject2] = useState();
    const [range2, setRange2] = useState(0);
    const [projectNumber2, setProjectNumber2] = useState('');

    const [show3, setShow3] = useState(false);
    const [manual3, setManual3] = useState(false);
    const [project3, setProject3] = useState();
    const [range3, setRange3] = useState(0);
    const [projectNumber3, setProjectNumber3] = useState('');

    const [show4, setShow4] = useState(false);
    const [manual4, setManual4] = useState(false);
    const [project4, setProject4] = useState();
    const [range4, setRange4] = useState(0);
    const [projectNumber4, setProjectNumber4] = useState('');

    const [show5, setShow5] = useState(false);
    const [manual5, setManual5] = useState(false);
    const [project5, setProject5] = useState();
    const [range5, setRange5] = useState(0);
    const [projectNumber5, setProjectNumber5] = useState('');

    const [show6, setShow6] = useState(false);
    const [manual6, setManual6] = useState(false);
    const [project6, setProject6] = useState();
    const [range6, setRange6] = useState(0);
    const [projectNumber6, setProjectNumber6] = useState('');

    const [show7, setShow7] = useState(false);
    const [manual7, setManual7] = useState(false);
    const [project7, setProject7] = useState();
    const [range7, setRange7] = useState(0);
    const [projectNumber7, setProjectNumber7] = useState('');

    const [errorText, setErrorText] = useState('');

    // const midnight = new Date();
    // midnight.setHours(0,0,0,0);    

    const defaultSingleValue = (options, propsValue) => {
        return options.filter(option => option.value === propsValue)[0];
    }

    useEffect(()=> {
        var newProjectOptions = props.projectOptions;
        if (newProjectOptions[0].value === 'null') {
            newProjectOptions.shift();
        }
        setLocalProjectOptions(newProjectOptions);
    }, [props.projectOptions]);

    useEffect(()=> {
        const rn = new Date();
        if (start > rn) {
            setErrorText('Cannot start timesheet in future');
            setIsDisabledStart(true);
        } else {
            setErrorText('');
            (!start || !location) ? setIsDisabledStart(true) : setIsDisabledStart(false)
        }
    }, [start, location]);

    useEffect(()=> {
        var s = moment(start);
        var e = moment(end);
        var d = (e.diff(s, 'days'));
        if ((start > end) && (d !== 0)) {
            setErrorText('Leave cannot end before it starts');
            setIsDisabledLeave(true);
        } else {
            setErrorText('');
            (!start || !end || !leaveType || !leaveHours) ? setIsDisabledLeave(true) : setIsDisabledLeave(false)
        }
    }, [start, end, leaveType, leaveHours]);

    const queryProjects = () => {
        const Project = Parse.Object.extend('Project');
        const query = new Parse.Query(Project);
        query.limit(999999);
        query.includeAll();
        query.ascending('projectId');
        query.find()
        .then(function(res) {
            var pArr = [];
            res.forEach(element => {
                pArr.push(element.attributes.projectId);
            });
            setAllProjects(pArr);
            setIsAllProjectsLoaded(true);
        }, (error) => {
            alert(error);
        });
    }

    useEffect(() => {
        if (!isAllProjectsLoaded && (manual1 || manual2 || manual3 || manual4 || manual5 || manual6 || manual7)) {
            queryProjects();
        }
        
    },[manual1, manual2, manual3, manual4, manual5, manual6, manual7]);

    const checkNumero = (num)=> {
        let regex = /\D/;
        let bool = regex.test(num);

        if (!bool && (num.length === 6)) {
            if (isAllProjectsLoaded) {
                const stringNums = JSON.stringify(allProjects);
                if (stringNums.includes(num.toString())) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    useEffect(()=> {
        if (showEnd) {
            const rn = new Date();
            if (end > rn) {
                setErrorText('Cannot end timesheet in future');
                setIsDisabledEnd(true);
            } else if (hours < 0.5) {
                setErrorText('Minimum 30 minute shift time');
                setIsDisabledEnd(true);
            } else if (hours > 18) {
                setErrorText('Maximum shift time exceeded');
                setIsDisabledEnd(true);
            } else if ((hours > 5) && (breakTime < 0.5)) {
                setErrorText('Minimum 30 minute break required');
                setIsDisabledEnd(true);
            } else if ((hours > 10) && (breakTime < 1)) {
                setErrorText('Minimum 1 hour break required');
                setIsDisabledEnd(true);
            } else if (rangeError) {
                setErrorText('Total hours must equal timesheet hours. Projects cannot be empty.');
                setIsDisabledEnd(true);
            } else if (show1 && ((manual1 && (checkNumero(projectNumber1))) || (!manual1 && !project1))) {
                setErrorText('Projects cannot be empty, and project number must be for an active project.');
                setIsDisabledEnd(true);
            } else if (show2 && ((manual2 && (checkNumero(projectNumber2))) || (!manual2 && !project2))) {
                setErrorText('Projects cannot be empty, and project number must be for an active project.');
                setIsDisabledEnd(true);
            } else if (show3 && ((manual3 && (checkNumero(projectNumber3))) || (!manual3 && !project3))) {
                setErrorText('Projects cannot be empty, and project number must be for an active project.');
                setIsDisabledEnd(true);
            } else if (show4 && ((manual4 && (checkNumero(projectNumber4))) || (!manual4 && !project4))) {
                setErrorText('Projects cannot be empty, and project number must be for an active project.');
                setIsDisabledEnd(true);
            } else if (show5 && ((manual5 && (checkNumero(projectNumber5))) || (!manual5 && !project5))) {
                setErrorText('Projects cannot be empty, and project number must be for an active project.');
                setIsDisabledEnd(true);
            } else if (show6 && ((manual6 && (checkNumero(projectNumber6))) || (!manual6 && !project6))) {
                setErrorText('Projects cannot be empty, and project number must be for an active project.');
                setIsDisabledEnd(true);
            } else if (show7 && ((manual7 && (checkNumero(projectNumber7))) || (!manual7 && !project7))) {
                setErrorText('Projects cannot be empty, and project number must be for an active project.');
                setIsDisabledEnd(true);
            } else {
                setErrorText('');
                (!end || !breakTime || !hours) ? setIsDisabledEnd(true) : setIsDisabledEnd(false)
            }
        }
    }, [end, breakTime, hours, rangeError, 
        show1, range1, manual1, projectNumber1, project1,
        show2, range2, manual2, projectNumber2, project2,
        show3, range3, manual3, projectNumber3, project3,
        show4, range4, manual4, projectNumber4, project4,
        show5, range5, manual5, projectNumber5, project5,
        show6, range6, manual6, projectNumber6, project6,
        show7, range7, manual7, projectNumber7, project7]);

    useEffect(()=>{
        if (rangeTotal === hours) {
            setRangeError(false);
        } else {
            setRangeError(true);
        }
    }, [rangeTotal, hours])

    useEffect(() => {
        queryActiveTimesheet(); 
    },[])

    const queryActiveTimesheet = () => {
        if (!checkedActive) {
            const Timesheet = Parse.Object.extend('Timesheet');
            const query = new Parse.Query(Timesheet);
            query.equalTo('active', true);
            query.equalTo('user', props.currentUser);
            query.find()
            .then(function(res) {
                if (res.length > 0) {
                    setActive(true);
                    setActiveId(res[0].id);
                    setStart(res[0].get('start'));
                    setLocation(defaultSingleValue(officeOptions, res[0].get('location')));
                    setCheckedActive(true);
                } else {
                    setActive(false);
                    setActiveId('');
                    setCheckedActive(true);
                }
            }, (error) => {
                setActive(false);
                setActiveId('');
                setCheckedActive(true);
                alert(error);
            });
        }
    }

    const handleCloseStart = () =>  {
        setShowStart(false);
        setErrorText('');
        setSubmitStringStart('Create Timesheet');
    }

    const handleShowStart = () => {
        setStart(new Date());
        setLocation(defaultSingleValue(officeOptions, props.currentUser.attributes.officeDefault));
        setErrorText('');
        setShowStart(true);
    }
      
    const handleSubmitStart = (e) => {
        e.preventDefault();
        setSubmitStringStart(<>
            Creating&nbsp;
            <Spinner
                as="span"
                animation="border"
                size="sm"
            />
        </>);

        const Timesheet = Parse.Object.extend('Timesheet');
        const timesheet = new Timesheet();

        timesheet.set('user', props.currentUser);
        timesheet.set('active', true);
        timesheet.set('location', location.value);
        timesheet.set('start', start);
        timesheet.set('leave', false);
        timesheet.set('approved', false);

        var timesheetACL = new Parse.ACL();
        timesheetACL.setRoleReadAccess('staff', true);
        timesheetACL.setRoleWriteAccess('staff', true);
        timesheet.setACL(timesheetACL);

        timesheet.save()
        .then((timesheet)=>{
            setActive(true);
            setActiveId(timesheet.id);
            handleCloseStart();
        }, (error) => {
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitStringStart('Create Timesheet');
        });
    }

    const handleCloseLeave = () =>  {
        setLeaveHours(7.5);
        setLeaveType('');
        setErrorText('');
        setStart(new Date());
        setEnd(new Date());
        setSubmitStringLeave('Lodge Leave');
        setShowLeave(false);
    }

    const handleShowLeave = () => {
        setShowLeave(true);
    }
      
    const handleSubmitLeave = (e) => {
        e.preventDefault();
        setSubmitStringLeave(<>
            Submitting&nbsp;
            <Spinner
                as="span"
                animation="border"
                size="sm"
            />
        </>);

        const Timesheet = Parse.Object.extend('Timesheet');
        const timesheet = new Timesheet();

        timesheet.set('user', props.currentUser);
        timesheet.set('active', false);
        timesheet.set('location', 'Leave');
        timesheet.set('start', start);
        timesheet.set('end', end);

        if (showHoursLeave) {
            timesheet.set('hours', leaveHours);
        }

        timesheet.set('leave', true);
        timesheet.set('leaveType', leaveType.value);
        timesheet.set('approved', false);

        var timesheetACL = new Parse.ACL();
        timesheetACL.setRoleReadAccess('staff', true);
        timesheetACL.setRoleWriteAccess('staff', true);
        timesheet.setACL(timesheetACL);

        timesheet.save()
        .then((timesheet)=>{
            var timeString = '';
            const startString = moment(start).locale('en-au').calendar({
                sameDay: 'ddd DD MMM',
                nextDay: 'ddd DD MMM',
                nextWeek: 'ddd DD MMM',
                lastDay: 'ddd DD MMM',
                lastWeek: 'ddd DD MMM',
                sameElse: 'ddd DD MMM' 
            });
            const endString = moment(end).locale('en-au').calendar({
                sameDay: 'ddd DD MMM',
                nextDay: 'ddd DD MMM',
                nextWeek: 'ddd DD MMM',
                lastDay: 'ddd DD MMM',
                lastWeek: 'ddd DD MMM',
                sameElse: 'ddd DD MMM' 
            });
            if (startString === endString) {
                timeString = 'on ' + startString + ' for ' + leaveHours + ' hours';
            } else {
                timeString = 'from ' + startString + ' to ' + endString;
            }

            const data = { text: props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + 
                ' has requested leave ' + timeString};

            axios.post('https://hooks.slack.com/services/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', JSON.stringify(data), {
                withCredentials: false,
                transformRequest: [
                    (data, headers) => {
                        return data;
                    },
                ],
            });
            handleCloseLeave();
        }, (error) => {
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitStringLeave('Lodge Leave');
        });
    }

    const handleCloseEnd = () =>  {
        setErrorText('');
        setSubmitStringEnd('Submit Timesheet');
        setSubmitStringDelete('Delete Timesheet');
        setEnd(new Date());
        setHours(0);
        setBreakTime(0);
        
        setRangeTotal(0);
        setRangeError(false);
        setProjCount(1);
        setProjMin(true);
        setProjMax(false);
    
        setShow1(true);
        setManual1(false);
        setProject1();
        setRange1(0);
        setProjectNumber1('');

        setShow2(false);
        setManual2(false);
        setProject2();
        setRange2(0);
        setProjectNumber2('');

        setShow3(false);
        setManual3(false);
        setProject3();
        setRange3(0);
        setProjectNumber3('');

        setShow4(false);
        setManual4(false);
        setProject4();
        setRange4(0);
        setProjectNumber4('');

        setShow5(false);
        setManual5(false);
        setProject5();
        setRange5(0);
        setProjectNumber5('');

        setShow6(false);
        setManual6(false);
        setProject6();
        setRange6(0);
        setProjectNumber6('');

        setShow7(false);
        setManual7(false);
        setProject7();
        setRange7(0);
        setProjectNumber7('');

        setShowEnd(false);
    }

    const handleShowEnd = () => {
        setShowEnd(true);
        calcHours(start, end, breakTime);
    }

    const handleSubmitEnd = (e) => {
        e.preventDefault();
        setSubmitStringEnd(<>
            Submitting&nbsp;
            <Spinner
                as="span"
                animation="border"
                size="sm"
            />
        </>);

        const Timesheet = Parse.Object.extend('Timesheet');
        const query = new Parse.Query(Timesheet);
        query.get(activeId)
        .then(function(timesheet) {
            timesheet.set('active', false);
            timesheet.set('start', start);
            timesheet.set('location', location.value);
            timesheet.set('end', end);
            timesheet.set('breakHours', breakTime);
            timesheet.set('hours', hours);

            var projArr = [];
            var projHoursArr = [];

            if (show1 && (range1 !== 0)) {
                if (manual1) {
                    projArr.push(projectNumber1);
                    projHoursArr.push(projectNumber1 + ':' + range1 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                } else {
                    const num1 = project1.label.slice(0,6);
                    projArr.push(num1);
                    projHoursArr.push(num1 + ':' + range1 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                }
            }

            if (show2 && (range2 !== 0)) {
                if (manual2) {
                    projArr.push(projectNumber2);
                    projHoursArr.push(projectNumber2 + ':' + range2 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                } else {
                    const num2 = project2.label.slice(0,6);
                    projArr.push(num2);
                    projHoursArr.push(num2 + ':' + range2 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                }
            }

            if (show3 && (range3 !== 0)) {
                if (manual3) {
                    projArr.push(projectNumber3);
                    projHoursArr.push(projectNumber3 + ':' + range3 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                } else {
                    const num3 = project3.label.slice(0,6);
                    projArr.push(num3);
                    projHoursArr.push(num3 + ':' + range3 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                }
            }

            if (show4 && (range4 !== 0)) {
                if (manual4) {
                    projArr.push(projectNumber4);
                    projHoursArr.push(projectNumber4 + ':' + range4 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                } else {
                    const num4 = project4.label.slice(0,6);
                    projArr.push(num4);
                    projHoursArr.push(num4 + ':' + range4 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                }
            }

            if (show5 && (range5 !== 0)) {
                if (manual5) {
                    projArr.push(projectNumber5);
                    projHoursArr.push(projectNumber5 + ':' + range5 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                } else {
                    const num5 = project5.label.slice(0,6);
                    projArr.push(num5);
                    projHoursArr.push(num5 + ':' + range5 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                }
            }

            if (show6 && (range6 !== 0)) {
                if (manual6) {
                    projArr.push(projectNumber6);
                    projHoursArr.push(projectNumber6 + ':' + range6 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                } else {
                    const num6 = project6.label.slice(0,6);
                    projArr.push(num6);
                    projHoursArr.push(num6 + ':' + range6 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                }
            }

            if (show7 && (range7 !== 0)) {
                if (manual7) {
                    projArr.push(projectNumber7);
                    projHoursArr.push(projectNumber7 + ':' + range7 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                } else {
                    const num7 = project7.label.slice(0,6);
                    projArr.push(num7);
                    projHoursArr.push(num7 + ':' + range7 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                }
            }

            timesheet.set('projects', projArr);
            timesheet.set('projectHours', projHoursArr);

            timesheet.save()
            .then((res)=>{
                setActive(false);
                setActiveId('');
                handleCloseEnd();
                props.handleCelebrate();
            }, (error) => {
                alert('Please send screenshot of this error message: ' + error.message);
                setSubmitStringEnd('Submit Timesheet');
            });
        }, (error) => {
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitStringEnd('Submit Timesheet');
        });
    }

    const handleSubmitDelete = (e) => {
        e.preventDefault();
        setSubmitStringDelete(<>
            Deleting&nbsp;
            <Spinner
                as="span"
                animation="border"
                size="sm"
            />
        </>);

        const Timesheet = Parse.Object.extend('Timesheet');
        const query = new Parse.Query(Timesheet);
        query.get(activeId)
        .then(function(timesheet) {
            timesheet.destroy()
            .then((res)=>{
                setActive(false);
                setActiveId('');
                handleCloseEnd();
            }, (error) => {
                alert('Please send screenshot of this error message: ' + error.message);
                setSubmitStringDelete('Delete Timesheet');
            });
        }, (error) => {
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitStringDelete('Delete Timesheet');
        });
    }

    const InactiveText = () => {
        if (props.currentUser.id === 'NS7ecaLkAU') {
            return <>
                &nbsp;
            </>
        } else {
            return <>
                <Col md={9}>
                    <i className="fas fa-exclamation-triangle"  style={{color:'var(--danger)'}}></i> No active timesheet <i className="fas fa-exclamation-triangle"  style={{color:'var(--danger)'}}></i>
                </Col>
            </>
        }
    }

    const ActiveText = () => {
        const startString = moment(start).locale('en-au').calendar({
            // sameDay: '[Today]',
            // nextDay: '[Tomorrow]',
            nextWeek: 'ddd DD MMM h:mm A',
            // lastDay: '[Yesterday]',
            lastWeek: 'ddd DD MMM h:mm A',
            sameElse: 'ddd DD MMM h:mm A' 
        });
        return <>
            <Col md={3}>
                Started: {startString}
            </Col>
            <Col md={3}>
                Location: {location.label}
            </Col>
        </>
    }

    // const HoursText = () => {
    //     const startString = moment(start).locale('en-au').calendar({
    //         // sameDay: '[Today]',
    //         // nextDay: '[Tomorrow]',
    //         nextWeek: 'ddd DD MMM h:mm A',
    //         // lastDay: '[Yesterday]',
    //         lastWeek: 'ddd DD MMM h:mm A',
    //         sameElse: 'ddd DD MMM h:mm A' 
    //     });
    //     const endString = moment(end).locale('en-au').calendar({
    //         // sameDay: '[Today]',
    //         // nextDay: '[Tomorrow]',
    //         nextWeek: 'ddd DD MMM h:mm A',
    //         // lastDay: '[Yesterday]',
    //         lastWeek: 'ddd DD MMM h:mm A',
    //         sameElse: 'ddd DD MMM h:mm A' 
    //     });
    //     return <>
    //         <Col md={4} style={{fontSize:'.875em'}}>
    //             <Row>
    //                 <Col md={12}>
    //                     Started {startString}
    //                 </Col>
    //             </Row>
    //             <Row>
    //                 <Col md={12}>
    //                     Ended {endString}
    //                 </Col>
    //             </Row>
    //         </Col>
    //         <Col md={4} style={{fontSize:'.875em'}}>
    //             <Row>
    //                 <Col md={12}>
    //                     <Stack direction='horizontal' gap={1}>
    //                         <span>
    //                             Break: {breakTime} hours    
    //                         </span>
    //                         <OverlayTrigger
    //                             placement="right"
    //                             delay={{ show: 250, hide: 400 }}
    //                             overlay={renderTooltip1}
    //                         >
    //                             <i className="fas fa-info-circle"></i>
    //                         </OverlayTrigger>
    //                     </Stack>
    //                 </Col>
    //             </Row>
    //             <Row>
    //                 <Col md={12}>
    //                     <Stack direction='horizontal' gap={1}>
    //                         <span>
    //                             Hours: {hours} hours 
    //                         </span>
    //                         <OverlayTrigger
    //                             placement="right"
    //                             delay={{ show: 250, hide: 400 }}
    //                             overlay={renderTooltip2}
    //                         >
    //                             <i className="fas fa-info-circle"></i>
    //                         </OverlayTrigger>
    //                     </Stack>
    //                 </Col>
    //             </Row>
    //         </Col>
    //         <Col md={4} style={{fontSize:'.875em'}}>
    //             <Row>
    //                 <Col md={12}>
    //                     Location: {location.label}
    //                 </Col>
    //             </Row>
    //         </Col>
    //     </>
    // }

    const renderTooltip1 = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Meal breaks are required under fair work legislation.
        </Tooltip>
    );

    const renderTooltip2 = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Hours are rounded to the nearest 15 minute increment in order to 
            facilitate easy input of project hours. 
        </Tooltip>
    );

    const handleLocationSelect = (e) => {
        setLocation(e);
    }

    const handleLeaveTypeSelect = (e) => {
        setLeaveType(e);
    }

    const handleLeaveStart = (date) => {
        var s = moment(date);
        var e = moment(end);
        var d = (e.diff(s, 'days'));
        if (d > 0) {
            setShowHoursLeave(false);
            setLeaveHours(7.5);
        } else {
            setShowHoursLeave(true);
        }
        setStart(date);
        // if (s > e) {
        //     setErrorText('Leave cannot end before it starts');
        //     // setStart();
        // } else {
        //     setErrorText('');
        //     setStart(date);
        // }
    }

    const handleLeaveEnd = (date) => {
        var s = moment(start);
        var e = moment(date);
        var d = (e.diff(s, 'days'));
        if (d > 0) {
            setShowHoursLeave(false);
            setLeaveHours(7.5);
        } else {
            setShowHoursLeave(true);
        }
        setEnd(date);
        // if (e < s) {
        //     setErrorText('Leave cannot end before it starts');
        //     // setEnd();
        // } else {
        //     setErrorText('');
        //     setEnd(date);
        // }
    }

    const handleDayStart = (date) => {
        var s = moment(date);
        var e = moment(end);
        if (e < s) {
            // setErrorText('Shift cannot end before it starts');
            // setStart();
        } else {
            setErrorText('');
            setStart(date);
            calcHours(date, end, breakTime);
        }
    }

    const handleDayEnd = (date) => {
        var s = moment(start);
        var e = moment(date);
        if (e < s) {
            // setErrorText('Shift cannot end before it starts');
            setEnd();
        } else {
            setErrorText('');
            setEnd(date);
            calcHours(start, date, breakTime);
        }
    }

    const handleBreakTime = (e) => {
        var b = Math.round(e.target.value / 0.25) * 0.25;
        setBreakTime(b);
        calcHours(start, end, b);
    }

    const setBreak30 = () => {
        setBreakTime(0.5);
        calcHours(start, end, 0.5);
    }

    const setBreak60 = () => {
        setBreakTime(1);
        calcHours(start, end, 1);
    }

    const setBreak90 = () => {
        setBreakTime(1.5);
        calcHours(start, end, 1.5);
    }

    const setBreak120 = () => {
        setBreakTime(2);
        calcHours(start, end, 2);
    }

    const calcHours = (s1, e1, b1) => {
        var s = moment(s1);
        var e = moment(e1);
        var m = (e.diff(s, 'minutes'));
        var h1 = +(((m/60) - b1));
        var h = Math.round(h1 / 0.25) * 0.25;
        setHours(h);
        if (h < 0.5) {
            setZeroTimesheet(true);
        } else {
            setZeroTimesheet(false);
        }
    }

    const addProj = () => {
        var cont = projCount;
        setProjCount(cont + 1);
        switch (cont + 1) {
            case 1:
                // doesn't exist
                break;
            case 2:
                setShow2(true);
                setProjMin(false);
                break;
            case 3:
                setShow3(true);
                break;
            case 4:
                setShow4(true);
                break;
            case 5:
                setShow5(true);
                break;
            case 6:
                setShow6(true);
                break;
            case 7:
                setShow7(true);
                setProjMax(true);
                break;
            default:
                break;
        }
    }

    const removeProj = () => {
        var cont = projCount;
        setProjCount(cont - 1);
        switch (cont) {
            case 1:
                // doesn't exist
                break;
            case 2:
                setShow2(false);
                setProjMin(true);
                setRange2(0);
                break;
            case 3:
                setShow3(false);
                setRange3(0);
                break;
            case 4:
                setShow4(false);
                setRange4(0);
                break;
            case 5:
                setShow5(false);
                setRange5(0);
                break;
            case 6:
                setShow6(false);
                setRange6(0);
                break;
            case 7:
                setShow7(false);
                setRange7(0);
                setProjMax(false);
                break;
            default:
                break;
        }
    }

    const handleRange1 = (range) => {
        setRange1(range);
        var h1 = (parseFloat(range) + parseFloat(range2) + parseFloat(range3) + parseFloat(range4) + parseFloat(range5) + parseFloat(range6) + parseFloat(range7))
        var h = Math.round(h1 / 0.25) * 0.25;
        setRangeTotal(h);
    }

    const handleRange2 = (range) => {
        setRange2(range);
        var h1 = (parseFloat(range1) + parseFloat(range) + parseFloat(range3) + parseFloat(range4) + parseFloat(range5) + parseFloat(range6) + parseFloat(range7))
        var h = Math.round(h1 / 0.25) * 0.25;
        setRangeTotal(h);    }

    const handleRange3 = (range) => {
        setRange3(range);
        var h1 = (parseFloat(range1) + parseFloat(range2) + parseFloat(range) + parseFloat(range4) + parseFloat(range5) + parseFloat(range6) + parseFloat(range7))
        var h = Math.round(h1 / 0.25) * 0.25;
        setRangeTotal(h);    
    }

    const handleRange4 = (range) => {
        setRange4(range);
        var h1 = (parseFloat(range1) + parseFloat(range2) + parseFloat(range3) + parseFloat(range) + parseFloat(range5) + parseFloat(range6) + parseFloat(range7))
        var h = Math.round(h1 / 0.25) * 0.25;
        setRangeTotal(h);        }

    const handleRange5 = (range) => {
        setRange5(range);
        var h1 = (parseFloat(range1) + parseFloat(range2) + parseFloat(range3) + parseFloat(range4) + parseFloat(range) + parseFloat(range6) + parseFloat(range7))
        var h = Math.round(h1 / 0.25) * 0.25;
        setRangeTotal(h);        }

    const handleRange6 = (range) => {
        setRange6(range);
        var h1 = (parseFloat(range1) + parseFloat(range2) + parseFloat(range3) + parseFloat(range4) + parseFloat(range5) + parseFloat(range) + parseFloat(range7))
        var h = Math.round(h1 / 0.25) * 0.25;
        setRangeTotal(h);        }

    const handleRange7 = (range) => {
        setRange7(range);
        var h1 = (parseFloat(range1) + parseFloat(range2) + parseFloat(range3) + parseFloat(range4) + parseFloat(range5) + parseFloat(range6) + parseFloat(range))
        var h = Math.round(h1 / 0.25) * 0.25;
        setRangeTotal(h);        }

    const SumText = () => {
        return <>
            Hours: {hours}<br />
            Total: {rangeTotal}
        </>
    }
    
    return <>
        {(!active && checkedActive) && (
            <Row>
                <Col md={9}>
                    <InactiveText />        
                </Col>
                <Col md={3}>
                    <Stack direction='horizontal' gap={1}>
                        <Button onClick={handleShowLeave} variant="outline-secondary">
                            Leave
                        </Button>
                        <Button onClick={handleShowStart} className='ms-auto'>
                            Start day
                        </Button>
                    </Stack>
                </Col>
            </Row>
        )}
        {(active && checkedActive) && (
            <Row>
                <Stack direction='horizontal' gap={1}>
                    <ActiveText />
                    <Button onClick={handleShowEnd} className='ms-auto'>
                        End day
                    </Button>
                </Stack>
            </Row>
        )}
        <Modal show={showStart} onHide={handleCloseStart} centered backdrop="static"> 
            <Modal.Header className='modal-header' closeButton>
                <Modal.Title>Start Timesheet</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <Form onSubmit={handleSubmitStart}>
                    <Row>
                        <Form.Group as={Col} md="6" className="mb-1">
                            <Form.Label>Start Time</Form.Label>
                            <DatePicker
                                dateFormat="dd/MM/yyyy h:mm aa"
                                className='form-control'
                                selected={start}
                                showTimeSelect
                                timeIntervals={15}
                                maxDate={new Date()}
                                // minTime={midnight}
                                // maxTime={new Date()}
                                onChange={(date)=> setStart(date)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-1">
                            <Form.Label>Location</Form.Label>
                            <Select 
                                styles={customStyles}
                                options={officeOptions}
                                onChange={(e)=>handleLocationSelect(e)}
                                value={location}
                                defaultValue={location}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <div className='form-fail'>
                            {errorText}
                        </div>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleCloseStart}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmitStart}
                    disabled={isDisabledStart}
                >
                    {submitStringStart}
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showLeave} onHide={handleCloseLeave} centered backdrop="static"> 
            <Modal.Header className='modal-header' closeButton>
                <Modal.Title>Leave</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <Form onSubmit={handleSubmitLeave}>
                    <Row>
                        <Form.Group as={Col} md="12" className="mb-1">
                            <Form.Label>Leave Type</Form.Label>
                            <Select 
                                styles={customStyles}
                                options={leaveOptions}
                                onChange={(e)=>handleLeaveTypeSelect(e)}
                                value={leaveType}
                                defaultValue={leaveType}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="6" className="mb-1">
                            <Form.Label>Start Date</Form.Label>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                className='form-control'
                                selected={start}
                                onChange={(date)=> handleLeaveStart(date)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-1">
                            <Form.Label>End Date</Form.Label>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                className='form-control'
                                selected={end}
                                onChange={(date)=> handleLeaveEnd(date)}
                            />
                        </Form.Group>
                    </Row>
                    {showHoursLeave && (
                        <Row>
                            <Form.Group as={Col} md="6">
                                <Form.Label className='pl-4'>Leave Hours</Form.Label>
                                <NumberFormat
                                    className="form-control"
                                    placeholder=""
                                    onChange={(e) => setLeaveHours(e.target.value)}
                                    value={leaveHours}
                                    displayType="input"
                                    type="text"
                                />
                            </Form.Group>
                        </Row>
                    )}                    
                    <Row>
                        <div className='form-fail'>
                            {errorText}
                        </div>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleCloseLeave}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmitLeave}
                    disabled={isDisabledLeave}
                >
                    {submitStringLeave}
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showEnd} onHide={handleCloseEnd} centered backdrop="static" size='lg'> 
            <Modal.Header className='modal-header' closeButton>
                <Modal.Title>End Timesheet</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <Form onSubmit={handleSubmitEnd}>
                    <Row>
                        <Form.Group as={Col} md="4" className="mb-1">
                            <Form.Label>Start Time</Form.Label>
                            <DatePicker
                                dateFormat="dd/MM/yyyy h:mm aa"
                                className='form-control'
                                selected={start}
                                showTimeSelect
                                timeIntervals={15}
                                maxDate={new Date()}
                                // minTime={midnight}
                                // maxTime={new Date()}
                                onChange={(date)=> handleDayStart(date)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="2">
                            <Form.Label className='pl-4'>Break (hours)</Form.Label>
                            <NumberFormat
                                className="form-control"
                                placeholder=""
                                onChange={(e) => handleBreakTime(e)}
                                value={breakTime}
                                displayType="input"
                                type="text"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Form.Label className='pl-4'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Form.Label>
                            <Stack direction='horizontal' gap={1}>
                                <Button onClick={setBreak30} variant="outline-secondary">
                                    30 min
                                </Button>
                                <Button onClick={setBreak60} variant="outline-secondary">
                                    60 min  
                                </Button>
                                <Button onClick={setBreak90} variant="outline-secondary">
                                    1.5 hours  
                                </Button>
                                <Button onClick={setBreak120} variant="outline-secondary">
                                    2 hours  
                                </Button>
                            </Stack>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="4" className="mb-1">
                            <Form.Label>End Time</Form.Label>
                            <DatePicker
                                dateFormat="dd/MM/yyyy h:mm aa"
                                className='form-control'
                                selected={end}
                                showTimeSelect
                                timeIntervals={15}
                                maxDate={new Date()}
                                // minTime={midnight}
                                // maxTime={new Date()}
                                onChange={(date)=> handleDayEnd(date)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" className="mb-1">
                            <Form.Label>Location</Form.Label>
                            <Select 
                                styles={customStyles}
                                options={officeOptions}
                                onChange={(e)=>handleLocationSelect(e)}
                                value={location}
                                defaultValue={location}
                            />
                        </Form.Group>
                        
                        <Form.Group as={Col} md={4} style={{fontSize:'.875em'}}>
                            <Row className='mt-4'>
                                <Col md={12}>
                                    <Stack direction='horizontal' gap={1}>
                                        <span>
                                            Break: {breakTime} hours    
                                        </span>
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip1}
                                        >
                                            <i className="fas fa-info-circle"></i>
                                        </OverlayTrigger>
                                    </Stack>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Stack direction='horizontal' gap={1}>
                                        <span>
                                            Hours: {hours} hours 
                                        </span>
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip2}
                                        >
                                            <i className="fas fa-info-circle"></i>
                                        </OverlayTrigger>
                                    </Stack>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Row>
                    {/* <Row className='mt-3 mb-3'>
                        <HoursText />   
                    </Row> */}
                     {(!zeroTimesheet) && (
                        <Row className='mt-3 mb-3'>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="5">
                                    <Form.Label>Project hours</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="7">
                                    <Form.Group as={Row}>
                                        <Col xs="9">
                                            &nbsp;
                                        </Col>
                                        <Col xs="3">
                                            <SumText/> 
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                            </Row>                        
                            {show1 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label=""
                                            checked={manual1}
                                            onChange={(e) => setManual1(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!manual1 && (
                                            <Select
                                                placeholder='Select project'
                                                styles={customStyles}
                                                options={localProjectOptions}
                                                value={project1}
                                                onChange={e=>setProject1(e)}
                                            />
                                        )}
                                        {manual1 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Project Number'
                                                value={projectNumber1}
                                                onChange={(e) => setProjectNumber1(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="9">
                                                <RangeSlider
                                                    value={range1}
                                                    onChange={e => handleRange1(e.target.value)}
                                                    variant='primary'
                                                    step={0.25}
                                                    max={hours}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <Form.Control 
                                                    value={range1}
                                                    onChange={(e) => handleRange1(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            {show2 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label=""
                                            checked={manual2}
                                            onChange={(e) => setManual2(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!manual2 && (
                                            <Select
                                                placeholder='Select project'
                                                styles={customStyles}
                                                options={localProjectOptions}
                                                value={project2}
                                                onChange={e=>setProject2(e)}
                                            />
                                        )}
                                        {manual2 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Project Number'
                                                value={projectNumber2}
                                                onChange={(e) => setProjectNumber2(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="9">
                                                <RangeSlider
                                                    value={range2}
                                                    onChange={e => handleRange2(e.target.value)}
                                                    variant='danger'
                                                    step={0.25}
                                                    max={hours}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <Form.Control 
                                                    value={range2}
                                                    onChange={(e) => handleRange2(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            {show3 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label=""
                                            checked={manual3}
                                            onChange={(e) => setManual3(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!manual3 && (
                                            <Select
                                                placeholder='Select project'
                                                styles={customStyles}
                                                options={localProjectOptions}
                                                value={project3}
                                                onChange={e=>setProject3(e)}
                                            />
                                        )}
                                        {manual3 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Project Number'
                                                value={projectNumber3}
                                                onChange={(e) => setProjectNumber3(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="9">
                                                <RangeSlider
                                                    value={range3}
                                                    onChange={e => handleRange3(e.target.value)}
                                                    variant='success'
                                                    step={0.25}
                                                    max={hours}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <Form.Control 
                                                    value={range3}
                                                    onChange={(e) => handleRange3(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            {show4 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label=""
                                            checked={manual4}
                                            onChange={(e) => setManual4(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!manual4 && (
                                            <Select
                                                placeholder='Select project'
                                                styles={customStyles}
                                                options={localProjectOptions}
                                                value={project4}
                                                onChange={e=>setProject4(e)}
                                            />
                                        )}
                                        {manual4 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Project Number'
                                                value={projectNumber4}
                                                onChange={(e) => setProjectNumber4(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="9">
                                                <RangeSlider
                                                    value={range4}
                                                    onChange={e => handleRange4(e.target.value)}
                                                    variant='warning'
                                                    step={0.25}
                                                    max={hours}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <Form.Control 
                                                    value={range4}
                                                    onChange={(e) => handleRange4(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            {show5 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label=""
                                            checked={manual5}
                                            onChange={(e) => setManual5(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!manual5 && (
                                            <Select
                                                placeholder='Select project'
                                                styles={customStyles}
                                                options={localProjectOptions}
                                                value={project5}
                                                onChange={e=>setProject5(e)}
                                            />
                                        )}
                                        {manual5 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Project Number'
                                                value={projectNumber5}
                                                onChange={(e) => setProjectNumber5(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="9">
                                                <RangeSlider
                                                    value={range5}
                                                    onChange={e => handleRange5(e.target.value)}
                                                    variant='info'
                                                    step={0.25}
                                                    max={hours}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <Form.Control 
                                                    value={range5}
                                                    onChange={(e) => handleRange5(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            {show6 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label=""
                                            checked={manual6}
                                            onChange={(e) => setManual6(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!manual6 && (
                                            <Select
                                                placeholder='Select project'
                                                styles={customStyles}
                                                options={localProjectOptions}
                                                value={project6}
                                                onChange={e=>setProject6(e)}
                                            />
                                        )}
                                        {manual6 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Project Number'
                                                value={projectNumber6}
                                                onChange={(e) => setProjectNumber6(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="9">
                                                <RangeSlider
                                                    value={range6}
                                                    onChange={e => handleRange6(e.target.value)}
                                                    variant='secondary'
                                                    step={0.25}
                                                    max={hours}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <Form.Control 
                                                    value={range6}
                                                    onChange={(e) => handleRange6(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            {show7 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label=""
                                            checked={manual7}
                                            onChange={(e) => setManual7(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!manual7 && (
                                            <Select
                                                placeholder='Select project'
                                                styles={customStyles}
                                                options={localProjectOptions}
                                                value={project7}
                                                onChange={e=>setProject7(e)}
                                            />
                                        )}
                                        {manual7 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Project Number'
                                                value={projectNumber7}
                                                onChange={(e) => setProjectNumber7(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="9">
                                                <RangeSlider
                                                    value={range7}
                                                    onChange={e => handleRange7(e.target.value)}
                                                    variant='dark'
                                                    step={0.25}
                                                    max={hours}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <Form.Control 
                                                    value={range7}
                                                    onChange={(e) => handleRange7(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            <Row>
                                <Form.Group as={Col} md="12">
                                    <Stack direction='horizontal' gap={1}>
                                        <Form.Label>Custom</Form.Label> 
                                        {!projMax && (
                                            <Button
                                                onClick={addProj}
                                                className='ms-auto'
                                                variant='outline-primary'
                                            >
                                                <i className="fas fa-plus"/>
                                            </Button>
                                        )}
                                        {(!projMin && !projMax) && (
                                            <Button
                                                onClick={removeProj}
                                                variant='outline-secondary'
                                            >
                                                <i className="fas fa-minus"/>
                                            </Button>
                                        )}
                                        {(projMax) && (
                                            <Button
                                                onClick={removeProj}
                                                className='ms-auto'
                                                variant='outline-secondary'
                                            >
                                                <i className="fas fa-minus"/>
                                            </Button>
                                        )}
                                    </Stack>
                                </Form.Group>
                            </Row>
                        </Row>
                    )}
                    <Row>
                        <div className='form-fail'>
                            {errorText}
                        </div>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleCloseEnd}>
                    Close
                </Button>
                {(!zeroTimesheet) && (
                    <Button
                        variant="primary"
                        onClick={handleSubmitEnd}
                        disabled={isDisabledEnd}
                    >
                        {submitStringEnd}
                    </Button>
                )}
                {(zeroTimesheet) && (
                    <Button
                        variant="danger"
                        onClick={handleSubmitDelete}
                    >
                        {submitStringDelete}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    </>
}
